import { useRef, memo } from 'react';
import classNames from 'classnames';
// eslint-disable-next-line import/no-internal-modules
import { useMenuOption } from '@askyourteam-nz/react-library/dist/elements/selection';
import { Row, RowItem } from '_Elements/layout';
import { Note, P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { SVGTickCircle } from '_Icons/main';
import { SYSTEM_COLOR } from '_Lib/Enum';

const Inner = memo(function Inner({ icon, rendered, subtitle, selected }) {
  return (
    <Row spacing={16}>
      {icon && (
        <RowItem inflexible >
          {icon}
        </RowItem>
      )}
      <RowItem column flexible style={{ width: 0 }} >
        <P
          className={'filter-selection__item-title'}
          textSize={PARAGRAPH_SIZES.P1}
          style={{ fontWeight: 400 }}
        >
          {rendered}
        </P>
        <Note
          textSize={PARAGRAPH_SIZES.P1}
          style={{ fontWeight: 400 }}
        >
          {subtitle}
        </Note>
      </RowItem>
      <RowItem right inflexible >
        <SVGTickCircle size={24} color={selected ? SYSTEM_COLOR.CHART.GREEN : 'transparent'} />
      </RowItem>
    </Row>
  );
});

const Option = (props) => {
  const {
    item,
    state
  } = props;

  const ref = useRef();

  const {
    focused,
    disabled,
    pressed,
    hovered,
    selected,
    optionProps,
    option,
    rendered
  } = useMenuOption(
    {
      item,
    },
    state,
    ref
  );

  const {
    icon,
    subtitle,
  } = option || {};

  return (
    <li
      ref={ref}
      data-component={'filter-selection-option'}
      className={
        classNames(
          'filter-selection__item',
          {
            ['filter-selection__item--focused']: focused,
            ['filter-selection__item--disabled']: disabled,
            ['filter-selection__item--pressed']: pressed,
            ['filter-selection__item--hovered']: hovered,
            ['filter-selection__item--selected']: selected
          }
        )
      }
      {...optionProps}
    >
      <Inner
        icon={icon}
        rendered={rendered}
        subtitle={subtitle}
        selected={selected}
      />
    </li>
  );
};

export default Option;
