import { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { clamp } from '_Lib/Utils';
import { useMeasure } from '_Hooks';
import { Column, ColumnItem } from '_Elements/layout';
import { SVGDots } from '_Icons/loading';
import { SYSTEM_COLOR } from '_Lib/Enum';
// eslint-disable-next-line import/no-cycle
import ListBox from './filter-selection-components/ListBox';
import './FilterSelection.scss';

const MIN_ITEM_WIDTH = 250;
const MAX_TITLE_LENGTH = 32;

export const FilterSelection = (props) => {
  const {
    loading,
    options: propsOptions,
    value,
    clearable,
    onChange: propsOnChange,
    subtitle
  } = props;

  const [
    bind,
    { width }
  ] = useMeasure();

  const onChange = useCallback(
    (keySet = new Set()) => {
      const option = propsOptions.find(({ value }) => value == [...keySet][0]);
      propsOnChange(option);
    },
    [propsOnChange, propsOptions]
  );

  const options = useMemo(
    () => propsOptions
      .map(
        ({ value, label, subtitle, icon }) => ({
          value,
          'aria-label': label,
          label: label.length > MAX_TITLE_LENGTH ? `${label.slice(0, MAX_TITLE_LENGTH)}…` : label,
          icon,
          subtitle
        })
      ),
    [propsOptions, subtitle]
  );

  const columnCount = clamp(Math.floor(width / MIN_ITEM_WIDTH), 1, 7);

  const values = useMemo(
    () => [value],
    [value]
  );

  const items = useMemo(
    () => options.map(
      ({ value, ...option }) => ({
        ...option,
        value,
        key: value
      })
    ),
    [options]
  );

  const disabledKeys = useMemo(
    () => items.filter(({ disabled }) => disabled).pluck('key'),
    [items]
  );

  return (
    <div
      {...bind}
      className={
        classNames(
          'filter-selection',
          `filter-selection--columns-${columnCount}`
        )
      }
      data-component={'filter-selection'}
    >
      {loading ? (
        <Column centerVertical centerHorizontal>
          <ColumnItem>
            <SVGDots size={16} color={SYSTEM_COLOR.PRIMARY} />
          </ColumnItem>
        </Column>
      ) : (
        <ListBox
          selectionMode={'single'}
          disabledKeys={disabledKeys}
          selectedKeys={values}
          clearable={clearable}
          items={items}
          onChange={onChange}
          aria-label={'Validated survey filter'}
        />
      )}
    </div>
  );
};
